.mapfilterbox {
  background-color: whitesmoke;
}

.filterOptions {
  padding: 1rem;
}

.singleFilterbox {
  margin-top: 1rem;
}

.filtersMain {
  :global {
    .MuiCheckbox-root {
      padding: 0;
      width: 20px;
      height: 20px;

      &.Mui-checked {
        background-color: #0094FF !important;
        
        svg {
          color: #FFFFFF !important;
          font-size: 20px;
        }
      }

      svg {
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
      }
    }

    .MuiFormControlLabel-label,
    .MuiTypography-root,
    .MuiButtonBase-root {
      font-weight: 400;
    }
  }

  .filterSelector {
    z-index: 9;
    position: fixed;
    bottom: 2%;
    right: 80px;
    box-sizing: border-box;
    width: 365px;
    border-radius: 4px;
    overflow: hidden;
    background: #1e1e24;

    .shipFiltersHead {
      background: #1e1e24;
      padding: 20px 16px;
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      svg {
        cursor: pointer;
        font-size: 20px;
      }
    }

    .accordionMain {
      background-color: transparent;
      border: none;
      box-shadow: none;
      margin: 0;

      &::before {
        display: none;
      }

      &.accordionExpanded {
        margin: 0;
      }

      .accordionWrap {
        padding: 8px 16px;
        min-height: unset;
        color: #fff;
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .accordionLabel {
          margin: 0;
          font-size: 14px;
          font-weight: 400;
          color: #fff;

          .MuiFormControlLabel-label {
            font-family: 'Inter', sans-serif;
            font-weight: 400;
          }
        }

        .expandMoreIcon {
          color: #fff;
          margin-left: auto;
          font-size: 20px;
        }
      }

      .accordionDetail {
        padding: 8px 16px;
        background: #1e1e24;
      }
    }
  }
}

.dataTypesContent,
.analyticsContent {
  display: flex;
  flex-direction: column;
  padding: 4px 0px 4px 16px;

  .dataTypeItem,
  .analyticsItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    cursor: pointer;
    height: 32px;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }

    .leftGroup {
      display: flex;
      align-items: center;
      flex: 1;
    }

    .checkboxMain {
      padding: 0;
      width: 20px;
      height: 20px;
      border-radius: 4px;

      &.Mui-checked {
        background-color: #0094FF !important;
        
        svg {
          color: #FFFFFF !important;
          font-size: 20px;
        }
      }

      svg {
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
      }
    }

    .filterIcon {
      width: 16px;
      height: 16px;
      object-fit: contain;
      display: flex;
      align-items: center;
    }

    span {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }

    .tooltipIcon,
    .tuneIcon {
      padding: 0;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }

      svg {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.legendCheckboxContainer {
  border-top: none;
}

.legendCheckbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px 24px 24px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  pointer-events: none;
  opacity: 0.5;
  min-height: 72px;
  box-sizing: border-box;

  span {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
}

.checkboxMain {
  color: #fff;
  padding: 0;
  width: 20px;
  height: 20px;

  &.checkboxChecked {
    color: #0094ff;
  }
}

.mapLayersWrap {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  justify-content: center;
  gap: 16px;
  cursor: pointer;

  .LayerContentWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .mapLayer {
      border-radius: 4px;
      background: transparent;
      width: 100px;
      height: 100px;
      overflow: hidden;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    .mapTitle {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
    }

    .LightImg {
      width: 100px;
      height: 100px;
      border-radius: 4px;
    }
  }

  .disabledWrap {
    cursor: not-allowed !important;
    opacity: 0.5;
  }

  .selectedBox {
    border: 2px solid #0094ff !important;
  }
}

.layerSelector {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .layerRow {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #fff;

    .MuiFormControlLabel-label {
      font-weight: 400;
    }
  }
}

.uncheckedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.checkedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #0094FF;
  border-radius: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    width: 6px;
    height: 12px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    box-sizing: border-box;
  }
}

.dataTypeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  min-height: 48px;
}

.leftGroup {
  display: flex;
  align-items: center;
  flex: 1;
}

.tuneIcon {
  margin-right: -8px;
  padding: 8px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

.checkboxLabel {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
}

.legendCheckboxLabel {
  width: 100%;
  margin: 0 !important;
}
