.timeout-dialog {
  z-index: 10002;

  .timeout-paper {
    min-width: 400px;
    border-radius: 8px;
    background-color: #24263c;
    color: white;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
  }

  .timeout-dialog-title {
    text-align: center;
    background-color: #393c56;
    color: white;
    padding: 16px 24px;
  }

  .timeout-dialog-content {
    padding: 24px;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .timeout-dialog-content-text {
      color: white;
      font-family: "Inter";
      font-weight: 400;
      line-height: 16.94px;
      text-align: center;
    }
  }

  .dialog-actions {
    background-color: #393c56;
    color: white;
    display: flex !important;
    justify-content: flex-end !important;
    min-height: 5em;
    padding: 1em;
    
    .keep-alive-button {
      min-width: 120px;
      text-transform: none !important;
      border: 1px solid #ffffff !important;
      background-color: #393c56 !important;
      color: white !important;

      &:hover {
        background-color: white !important;
        color: black !important;
      }
    }
  }
}