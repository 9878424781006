.errorContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;

  .errorCard {
    width: 100%;
    max-width: 450px;
    background-color: #1a1a1a;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);

    .errorContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .errorIcon {
        color: #ff4444;
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
      }

      .errorTitle {
        color: #ffffff;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .errorMessage {
        color: #999999;
        font-size: 16px;
        margin-bottom: 24px;
        line-height: 1.5;
      }

      .resetButton {
        background-color: #0094ff;
        color: #ffffff;
        padding: 8px 24px;
        border: none;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #0077cc;
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(0, 148, 255, 0.5);
        }
      }
    }
  }
} 