.singleFilterbox {
  margin-top: 1rem;
}
.shipFiltersMain {
  .shipFiltersHead {
    background: #24263c;
    padding: 15px;
    color: #fff;
    span {
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .icon {
      cursor: pointer;
    }
  }
  .shipFiltersWrapper {
    background-color: #393c56;
    padding: 24px 15px;
    overflow-y: scroll;
    height: 60vh;
    .clearInputIcon {
      margin-left: auto;
      cursor: pointer;
    }
    .shipFilterDisable {
      opacity: 0.4;
      cursor: not-allowed;
    }
    .shipFiltersDetail {
      .shipFiltersLabel {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #ffffff;
      }
      .editMainSelect {
        color: #fff;
      }
      .countrySelectMain {
        .countrySelect {
          border: 1px solid #ffffff40;
          background: #00000080;
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          color: #ffffff;
          padding: 8px 12px;
          border-radius: 4px;
          &:has(.countryInput:focus) {
            border-color: #00a3e3;
          }
          .countrySelectImage {
            height: 16px;
            margin-right: 6px;
          }
          .countryInput {
            min-height: 20px;
            border: 0;
            background: transparent;
            padding: 0;
            outline: none;
            color: #fff;
          }
        }
      }
      .shipFiltersListWrap {
        border: 1px solid #ffffff40;
        max-height: 160px;
        overflow: hidden;
        border-radius: 4px;
        margin-bottom: 5px !important;

        ::-webkit-scrollbar {
          display: none;
        }
        .shipFiltersListHead {
          background: #26262c;
          .shipFiltersListBox {
            padding: 0;
            .shipFiltersList {
              padding: 8px 12px;
              .listImage {
                height: 16px;
              }
              .listLabel {
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                color: #fff;
              }
              &:hover,
              &.active {
                background-color: #00a3e34d;
              }
            }
          }
        }
      }
      .colorPickerMain {
        display: flex;
        align-items: center;
        gap: 8px;
        
        .colorPicker {
          flex: 1;
          min-width: 0;
        }
      }
      .sliderWrapper {
        .slider {
          width: 90%;
          margin: 0 auto;
          .sliderRail {
            color: #d9d9d9;
          }
          .sliderTrack {
            color: #ffffff;
          }
          .sliderThumb {
            color: #0094ff;
            border: 2px solid #fff;
          }
        }
      }
      .checkboxMain {
        color: #fff;
        padding: 0;
        &.Mui-checked {
          color: #FFFFFF;
          background-color: #0094FF;
          border-radius: 4px;
          svg {
            color: #FFFFFF;
          }
        }
        svg {
          color: #FFFFFF;
        }
      }
    }
  }
  .filterButton {
    padding: 0 15px 24px;
    .shipFiltersButton {
      border: 1px solid #ffffff;
      padding: 8px;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      color: #ffffff;
      text-transform: capitalize;
    }
  }
}

.uncheckedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.checkedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #0094FF;
  border-radius: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #FFFFFF;
    transform: translate(-50%, -50%) rotate(45deg);
    clip-path: polygon(14% 44%, 0% 65%, 50% 100%, 100% 0%, 80% 0%, 45% 55%);
  }
}

.shipIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  
  svg {
    width: 100%;
    height: 100%;
    min-width: 24px;
    min-height: 24px;
    object-fit: contain;
    overflow: visible;
    
    path {
      transform-origin: center;
      transform: scale(1);
    }
  }
}

.shipLegendContainer {
  background-color: #393c56;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  max-width: 360px;
}

.shipLegendHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #24263c;
  padding: 15px;
  color: #fff;

  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  .closeButton {
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

.shipLegendContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 10px;
}

.iconWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shipIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.legendLabel {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
}
