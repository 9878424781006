.dataTypeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 0;
  cursor: pointer;
  height: 32px;

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }

  .leftGroup {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 8px;
  }

  .checkboxMain {
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 4px;

    &:global(.MuiCheckbox-root) {
      padding: 0;
      width: 20px;
      height: 20px;

      &.Mui-checked {
        background-color: #0094FF !important;
        
        svg {
          color: #FFFFFF !important;
          font-size: 20px;
        }
      }

      svg {
        color: rgba(255, 255, 255, 0.7);
        font-size: 20px;
      }
    }
  }

  .filterIcon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .labelText {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }

  .tooltipIcon,
  .tuneIcon {
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
    }

    svg {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}

.uncheckedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.checkedIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #0094FF;
  border-radius: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    width: 6px;
    height: 12px;
    border: solid #FFFFFF;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    box-sizing: border-box;
  }
}

.tooltipStyle {
  font-size: 14px;
  color: #FFFFFF;
  background-color: #24263C;
  padding: 8px 12px;
  border-radius: 4px;
}

.tuneIconButton {
  margin-right: -8px;
  padding: 8px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
}

.tuneIcon {
  cursor: pointer;
  
  &.tuneIconDisabled {
    opacity: 0.38;
    cursor: default;
    
    svg {
      color: rgba(0, 0, 0, 0.26);
      cursor: default;
    }

    &:hover {
      cursor: default;
    }
  }
} 