.legendContainer {
  z-index: 9999;
  position: fixed;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  background-color: #111326;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 20px;
  max-width: 90%;
  overflow: auto;
  cursor: grab;
  
  &:hover {
    cursor: grab;
  }
  
  &:active {
    cursor: grabbing;
  }
  
  &.dragging {
    cursor: grabbing;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.itemLabel {
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
} 