.pathSettingMenuRoot {
  .pathSettingMenuPaper {
    width: 100%;
    max-width: 202px;
    border-radius: 8px;
    background-color: #00000080;
    overflow: visible;

    &::after {
      content: "";
      position: absolute;
      top: -6px;
      right: 15px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 6px solid #00000080;
    }
    .pathSettingMenuList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;
      .pathSettingMenuItemWrap {
        display: flex;
        flex-direction: column;
        gap: 12px;
        .pathSettingMenuItem {
          min-height: auto;
          padding: 0;
          &:hover {
            background-color: transparent;
          }
          .pathSettingFormControl {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: 0;

            .pathSettingCheckbox {
              width: 20px;
              height: 20px;
              padding: 0;
              color: #fff;
              background-color: #1b1d2d;
              &.itemChecked {
                color: #0094ff;
              }
            }
          }
        }
      }
      .pathSettingLabel {
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        font-family: "Inter";
        line-height: 17px;
      }
    }
  }
}
