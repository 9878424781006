.overviewMain {
  .overviewWrap {
    .overViewImage {
      width: 225px;
      height: 225px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
  .overviewDetail {
    gap: 10px;
  }
  .overViewDivider {
    background: #393c56;
  }
}
.detailWrap {
  gap: 2px;
  .detailHead {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: #7d7d84;
  }
  .detailValue {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #fff;
  }
}
